import $ from 'jquery';
import 'what-input';




// import lightGallery from 'lightgallery';
// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';



// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


//applies a style to the active link
document.querySelectorAll('.navLink').forEach
(link => {
  if(link.href === window.location.href){
    link.setAttribute('aria-current', 'page')
  }
})

$(document).ready(function() {
  //unencoding the email
  var encEmail = "ZGVib3JhaG1vdHRmaW5lYXJ0QGdtYWlsLmNvbQ==";
  const form = document.getElementById("contact");
  form.setAttribute("href", "mailto:".concat(atob(encEmail)));
})


$(document).ready(function() {
  var $isGallery = $('.myLBGallery');
  var $boxes = $('.isGallery-large');
			$boxes.hide();

			$isGallery.imagesLoaded( {background: true}, function() {
    		$boxes.fadeIn();

  $isGallery.isotope({
    // options
    itemSelector: '.isGallery-large',
    sortBy: 'original-order',
    percentPosition: true,
    masonry: {
      columnWidth: '.isGallery-large'
    }
  });

});


  lightGallery(document.getElementById('isGallery'), {
          licenseKey: 'your_license_key',
          speed: 500,
          mode: 'lg-fade',
          download: false,
          thumbnail: false,
          actualSize: false,
          showZoomInOutIcons: true,
          plugins: [lgZoom],
          hideBarsDelay: 1000,
          mobileSettings: {
            zoom: false,
            controls: false
          }

          // ... other settings
      });

});



  // listens for orientationchange event, calls the location.reload() method to reload the page.
    window.addEventListener("orientationchange", function() {
    location.href = location.href + "?t=" + new Date().getTime();
  });


  //returns page to the top
  $('.returnScroll').click(function() {
    $(window).scrollTop(0);
  });

  //Hamburger menu
  $(document).ready(function() {
    var timerId, delay = 400;
    var a = $("#A"),
        b = $("#B"),
        c = $(".menuUL2"),
        d = $(".mainPages"),
        e = $("#E")

    function stopHide() {
      clearTimeout(timerId);
    }

    // function showMenu() {
    //   b.slideDown();
    // }

    function clickMenu() {
      b.slideToggle();
    }

    function startHide() {
      timerId = setTimeout(function() {
        b.slideUp();
      }, delay);
    }
    // a.mouseenter(clickMenu).mouseenter(stopHide).mouseleave(startHide);
    a.click(clickMenu).click(stopHide);
    b.mouseenter(stopHide).mouseleave(startHide);
    c.click(startHide);
    d.click(startHide);
    e.click(clickMenu).click(stopHide);
  });

//   //create a nested accordion menu
// const galleryNav = document.querySelector('.galleryNav');
// const nestedMenuHeaders = galleryNav.querySelectorAll('.nestedMenuHeader');
//
// nestedMenuHeaders.forEach(nestedMenuHeader => {
//   nestedMenuHeader.addEventListener('click', () => {
//     const nestedMenu = nestedMenuHeader.nextElementSibling;
//     nestedMenu.classList.toggle('accordion');
//     const isAccordion = nestedMenu.classList.contains('accordion');
//     const height = isAccordion ? nestedMenu.scrollHeight + "px" : 0;
//     nestedMenu.style.maxHeight = height;
//   });
//
//   // check if the active link is in the nested menu and keep accordion open if true
//   const nestedMenuLinks = nestedMenuHeader.nextElementSibling.querySelectorAll('.navLink');
//   nestedMenuLinks.forEach(nestedLink => {
//     if(nestedLink.href === window.location.href){
//       const nestedMenu = nestedMenuHeader.nextElementSibling;
//       nestedMenu.classList.add('accordion');
//       nestedMenu.style.maxHeight = nestedMenu.scrollHeight + "px";
//     }
//   });
// });

//sets up show on scroll
// Detect request animation frame
var scroll = window.requestAnimationFrame ||
       // IE Fallback
       function(callback){ window.setTimeout(callback, 1000/60)};
var elementsToShow = document.querySelectorAll('.show-on-scroll');

function loop() {

Array.prototype.forEach.call(elementsToShow, function(element){
if (isElementInViewport(element)) {
  element.classList.add('is-visible');
} else {
  element.classList.remove('is-visible');
}
});

scroll(loop);
}

// Call the loop for the first time
loop();

// Helper function for show on scroll from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
// special bonus for those using jQuery
if (typeof jQuery === "function" && el instanceof jQuery) {
el = el[0];
}
var rect = el.getBoundingClientRect();
return (
(rect.top <= 0
&& rect.bottom >= 0)
||
(rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
rect.top <= (window.innerHeight || document.documentElement.clientHeight))
||
(rect.top >= 0 &&
rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
);
}
